<template>
  <van-button type="primary" size="large" :to="{ name: 'ProductCreate'}">添加宝贝</van-button>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getProducts"
  >
    <template v-for="item in items" :key="item">
      <van-card
        :price="item.price"
        :desc="`销量 ${item.sales}`"
        :title="item.name"
        :thumb="item.image"
      >
        <template #tags>
          <van-tag plain type="danger">id: {{ item.productId }}</van-tag>&nbsp;
          <template v-if="!item.onSale">
            <van-tag plain type="danger">已下架</van-tag>&nbsp;
          </template>
        </template>
        <template #footer>
          <van-button size="small" :to="{ name: 'ProductEdit', query: { productId: item.productId } }">编辑</van-button>
          <van-button size="small" @click="onDelete(item.productId)">删除</van-button>
        </template>
      </van-card>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      items: []
    })
    const onDelete = (id) => {
      Dialog.confirm({
        message: '确认删除？'
      }).then(() => {
        post('/product.delete', {
          productIds: id.toString().split(',')
        }).then((res) => {
          if (res.code === 0) {
            Toast.success()
            state.page = 1
            state.finished = false
            state.loading = false
            state.items = []
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {
      })
    }
    const getProducts = () => {
      post('/product.list', {
        pageNum: state.page,
        storeId: Cookies.get('storeId'),
        sort: 'sort desc',
        bizScope: 'GENERAL'
      }).then(res => {
        state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getProducts,
      onDelete
    }
  }
}
</script>
<style scoped>
</style>
